import type { ImageField } from '@prismicio/types';
import Head from 'next/head';
import React from 'react';

import type { IAuthors } from '@components/Pages/Blog/BlogAuthor/authorsData';
import { authorsData } from '@components/Pages/Blog/BlogAuthor/authorsData';
import { routes } from '@routes';

interface IProps {
  title: string;
  preview: string;
  date: string | null;
  uid?: string;
  coverImage: ImageField;
  author: IAuthors | null;
}

export const BlogPostStructuredData: React.FC<IProps> = ({
  title,
  preview,
  date,
  uid,
  author,
  coverImage,
}) => (
  <Head>
    <script
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${process.env.NEXT_PUBLIC_APP_URL ?? ''}${routes.blog}/${uid}"
          },
          "headline": "${title}",
          "description": "${preview}",
          "image": {
            "@type": "ImageObject",
            "url": "${coverImage.url}",
            "width": 960,
            "height": 420
          },
          "author": {
            "@type": "Person",
            "name": "${author ? authorsData[author].name : ''}"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "CDN77",
            "logo": {
              "@type": "ImageObject",
              "url": "${process.env.NEXT_PUBLIC_APP_URL ?? ''}/cdn77.svg",
              "width": 160,
              "height": 36
            }
          },
          "datePublished": "${date}",
          "dateModified": "${date}"
        }`,
      }}
      // eslint-disable-next-line react/no-danger
      type="application/ld+json"
    />
  </Head>
);
