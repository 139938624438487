import React from 'react';

interface IProps {
  date: string;
}

export const DateFormat: React.FC<IProps> = ({ date }) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const convertedDate = new Date(date);
  const day = convertedDate.getDate();
  const monthIndex = convertedDate.getMonth();
  const year = convertedDate.getFullYear();

  return (
    <>
      {day} {monthNames[monthIndex]} {year}
    </>
  );
};
