import React from 'react';

import { Paragraph } from '@components/Paragraph/Paragraph';

import styles from './Note.module.scss';
import InfoIcon from '@icons/info.svg';

interface IProps {
  renderAsParagraph?: boolean;
  children: React.ReactNode;
}

export const Note: React.FC<IProps> = ({ renderAsParagraph = true, children }) => (
  <div className={styles.wrapper}>
    <div className={styles.iconWrapper}>
      <InfoIcon className={styles.icon} />
    </div>
    {renderAsParagraph ? (
      <Paragraph color="dark" size="xs">
        {children}
      </Paragraph>
    ) : (
      children
    )}
  </div>
);
