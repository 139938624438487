import { asText } from '@prismicio/helpers';
import React from 'react';

import { DateFormat } from '@components/DateFormat/DateFormat';
import { Link } from '@components/Link/Link';
import type { IBlogPost } from '@prismic';
import { routes } from '@routes';

import styles from './BlogLatestArticles.module.scss';

interface IProps {
  articles: IBlogPost[];
}

export const BlogLatestArticles: React.FC<IProps> = ({ articles }) => (
  <div className={styles.wrapper}>
    <strong className={styles.title}>Latest articles</strong>
    <ul className={styles.list}>
      {articles.map(({ uid, data }) => (
        <li key={uid} className={styles.item}>
          <Link
            className={styles.link}
            href={`${routes.blog}/${uid}`}
            label={`go to ${asText(data.title)}`}
          >
            <article className={styles.content}>
              {data.cover_image?.url && (
                <figure className={styles.imgWrapper}>
                  <img
                    alt={data.cover_image.alt ?? ''}
                    className={styles.img}
                    sizes="(max-width: 575px) 532px, 176px"
                    src={data.cover_image.url}
                  />
                </figure>
              )}
              <div>
                <time className={styles.date} dateTime={data.date}>
                  <DateFormat date={data.date} />
                </time>
                <strong className={styles.label}>{asText(data.title)}</strong>
              </div>
            </article>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
