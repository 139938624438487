import React from 'react';

import type { IAuthors } from './authorsData';
import { authorsData } from './authorsData';
import { Button } from '@components/Button/Button';
import { Image } from '@components/Image/Image';

import styles from './BlogAuthor.module.scss';

interface IProps {
  author: IAuthors;
}

export const BlogAuthor: React.FC<IProps> = ({ author }) => {
  const { email, name, photo, role } = authorsData[author];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.photoWrapper}>
          <Image alt={name} height={100} src={photo} width={100} />
        </div>
        <div>
          <strong className={styles.name}>{name}</strong>
          <p className={styles.role}>{role}</p>
        </div>
      </div>
      {email && (
        <Button href={`mailto:${email}`} size="md" theme="button" variant="secondary">
          Get in touch
        </Button>
      )}
    </div>
  );
};
